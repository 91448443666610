import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDTEs } from 'redux/actions/users';
import { getPermission } from 'utils';
import { TfiAlert } from "react-icons/tfi";
import useGetAuthUserProfile from 'api/services/user/queries/getUserSessionProfile';

const ErrorBanner = ({ number }) => {
  const [isVisible, setIsVisible] = useState(true);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const dtesResponse = useSelector((state) => state.users.getDTEs);
  const getProfile = useGetAuthUserProfile();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDTEs());
    const intervalId = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 5000); // Cambiar el estado cada segundo (1000 ms)

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [dtes, setDtes] = useState([]);

  useEffect(() => {
    if (dtesResponse) if (dtesResponse.data) setDtes(dtesResponse.data.dtes);
  }, [dtesResponse]);

  useEffect(() => {
    dispatch(getDTEs());
  }, []);

  const getMaxToleranceDate = () => {
    let profile = getProfile?.data
    let management = profile?.management
    let maxToleranceDate = management.max_tolerance_date;
    // Fecha objetivo en formato YYYY-MM-DD
    const fechaObjetivo = new Date(maxToleranceDate);

    // Fecha actual
    const fechaActual = new Date();

    // Calcula la diferencia en milisegundos
    const diferenciaMs = fechaObjetivo - fechaActual;

    // Convierte la diferencia a días
    const msEnUnDia = 24 * 60 * 60 * 1000;
    const diasFaltantes = Math.ceil(diferenciaMs / msEnUnDia);

    return diasFaltantes;
  }

  return (
    <>
      {isMobile && isVisible && (
        <div style={isVisible ? errorBannerStyle : hiddenBannerStyle}>
          <p>
          <bold style={{fontWeight: 'bold'}}><TfiAlert />Aviso Importante: Factura Vencida<TfiAlert /></bold>&nbsp;<br/>
          Para evitar suspensión del servicio regularice sus pagos en los próximos ({getMaxToleranceDate()}) días 
          </p>
          {getPermission(['superadmin']) &&
            dtes.map((dte) => {
              if (dte.status == 'Documento Vencido')
                return (
                  <div>
                    <a href={dte.url} target="_blank">
                      Factura Nº {dte.folio} - Fecha de emisión {dte.fecha_emision}
                    </a>
                  </div>
                );
            })}
        </div>
      )}
      {!isMobile && (
        <div style={isVisible ? errorBannerStyle : hiddenBannerStyle}>
          <p>
          <bold style={{fontWeight: 'bold'}}><TfiAlert />Aviso Importante: Factura Vencida<TfiAlert /></bold>&nbsp;<br/>
          Regularice sus pagos en los próximos ({getMaxToleranceDate()}) días o su acceso será suspendido.
          </p>
          {getPermission(['superadmin']) &&
            dtes.map((dte) => {
              if (!dte.fecha_pago)
                return (
                  <div>
                    <a href={dte.url} target="_blank">
                      Factura Nº {dte.folio} - Fecha de emisión {dte.fecha_emision}
                    </a>
                  </div>
                );
            })}
        </div>
      )}
    </>
  );
};

const errorBannerStyle = {
  backgroundColor: 'red',
  color: 'white',
  padding: '4px',
  textAlign: 'center',
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  zIndex: '9999',
  transition: 'opacity 0.5s ease-in-out',
  pointerEvents: 'auto', // Permitir interacción
};

const hiddenBannerStyle = {
  ...errorBannerStyle,
  opacity: 0,
  pointerEvents: 'none', // Ignorar interacción
};

export default ErrorBanner;
