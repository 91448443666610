import { create } from 'zustand';
import { ExportCenterFiltersState, ExportCenterState } from './types';

export const useExportCenterStore = create<ExportCenterState>((set) => ({
  tickets: {},
  maintenances: {},
  observationsMaintenances: '',
  visits: {},
  documents: {},
  observationsGeneral: '',
  addTickets: (items) =>
    set((state) => {
      const tickets = { ...state.tickets };
      items.forEach((item) => {
        tickets[item.uuid] = item;
      });
      return { tickets };
    }),
  removeTickets: (items) =>
    set((state) => {
      const tickets = { ...state.tickets };
      items.forEach((item) => {
        delete tickets[item.uuid];
      });
      return { tickets };
    }),
  addMaintenances: (items) =>
    set((state) => {
      const maintenances = { ...state.maintenances };
      items.forEach((item) => {
        maintenances[item.uuid] = item;
      });
      return { maintenances };
    }),
  removeMaintenances: (items) =>
    set((state) => {
      const maintenances = { ...state.maintenances };
      items.forEach((item) => {
        delete maintenances[item.uuid];
      });
      return { maintenances };
    }),
  setObservationMaintenances: (text) => set({ observationsMaintenances: text }),
  addVisits: (items) =>
    set((state) => {
      const visits = { ...state.visits };
      items.forEach((item) => {
        visits[item.uuid] = item;
      });
      return { visits };
    }),
  removeVisits: (items) =>
    set((state) => {
      const visits = { ...state.visits };
      items.forEach((item) => {
        delete visits[item.uuid];
      });
      return { visits };
    }),
  addDocuments: (items) =>
    set((state) => {
      const documents = { ...state.documents };
      items.forEach((item) => {
        documents[item.uuid] = item;
      });
      return { documents };
    }),
  removeDocuments: (items) =>
    set((state) => {
      const documents = { ...state.documents };
      items.forEach((item) => {
        delete documents[item.uuid];
      });
      return { documents };
    }),
  setObservationGeneral: (text) => set({ observationsGeneral: text }),
  clearAll: () => set({
    tickets: {},
    maintenances: {},
    observationsMaintenances: '',
    visits: {},
    documents: {},
    observationsGeneral: '',
  }),
}));

export const useExportCenterFiltersStore = create<ExportCenterFiltersState>((set) => ({
  community: null,
  setCommunity: (value) => set({ community: value }),
}));
