import React from "react";
import CertificationsTable from "./CertificationsTable";
import { MaintenanceModel } from "api/services/maintenances/models";
import UploadFileDrawer from "components/UploadFileDrawer";
import { usePermissions } from "stores/authentication/helpers";
import { Box } from "@mui/material";

const CertificationsTab: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const canEditDocuments = usePermissions().documents.edit;

  return (
    <Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        {canEditDocuments && (
          <UploadFileDrawer
            user={undefined}
            color={"success"}
            title="Subir documento"
            slugType={"certificacion"}
            maintenance={maintenance}
            ticket={undefined}
            areaUuid={undefined}
            checklistItemUuid={undefined}
            alignButton={undefined}
            variant={undefined}
            community={undefined}
            visitReport={undefined}
            fromReport={undefined}
          />
        )}
      </Box>
      <Box mt={2}>
        <CertificationsTable maintenance={maintenance} />
      </Box>
    </Box>
  );
};

export default CertificationsTab;
