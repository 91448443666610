import React, { FC, useMemo, useState } from "react";
import { Box, Card } from "@mui/material";
import { TicketItemModel } from "api/services/tickets/models";
import { SuiBoxTs } from "components/SuiBox";
import SwappiDataTable from "components/SwappiTable";
import { ColumnTable } from "components/SwappiTable/types";
import Filters from "./Filters";
import {
  parseOrderBy,
  parsePriority,
  parseStatus,
} from "api/services/tickets/helpers";
import { TicketsDataFilter } from "./types";
import { getColumns } from "./helpers";
import { useExportCenterFiltersStore, useExportCenterStore } from "../store";
import { useGetTicketsPage } from "api/services/tickets/queries/getTicketsPage";

const SelectTicketsTable = () => {
  const {
    addTickets,
    removeTickets,
    tickets: selectedTickets,
  } = useExportCenterStore();
  const [dataFilters, setDataFilters] = useState<TicketsDataFilter>({
    title: "",
    community: useExportCenterFiltersStore.getState().community,
    member: "",
    priorityFilter: {},
    statusFilter: {},
    orderByFilter: "none",
    sortBy: "",
    activeTicketsFilter: false,
    responsible: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const { data, isLoading, refetch } = useGetTicketsPage({
    search: dataFilters.title,
    communities: dataFilters.community,
    priority__in: parsePriority(dataFilters.priorityFilter),
    status__in: parseStatus(dataFilters.statusFilter),
    o: parseOrderBy(dataFilters.orderByFilter, dataFilters.sortBy),
    is_inactive: dataFilters.activeTicketsFilter,
    assignees: dataFilters.member,
    page: currentPage,
    page_size: pageSize,
    responsible: dataFilters.responsible,
  });

  const columns = useMemo<ColumnTable<TicketItemModel>[]>(() => {
    return getColumns({
      selectedTickets,
      data,
      refetch,
      addTickets,
      removeTickets,
    });
  }, [data, selectedTickets]);

  return (
    <Box>
      <Card sx={{ p: 2, overflow: "visible", mb: 2 }}>
        <Filters setFilters={setDataFilters} />
      </Card>
      <Card sx={{ px: 2 }}>
        <SuiBoxTs>
          <SwappiDataTable
            columns={columns}
            data={data?.results}
            isLoading={isLoading}
            defaultPageSize={pageSize}
            onChangePageSize={setPageSize}
            pagination={{
              count: data?.count,
              previous: data?.previous,
              next: data?.next,
              currentPage,
              onChangePage: setCurrentPage,
            }}
          />
        </SuiBoxTs>
      </Card>
    </Box>
  );
};

export default SelectTicketsTable;
