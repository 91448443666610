import React from "react";
import UploadFileDrawer from "components/UploadFileDrawer";
import { Box } from "@mui/material";
import { MaintenanceModel } from "api/services/maintenances/models";
import ContractsTable from "./ContractsTable";
import { usePermissions } from "stores/authentication/helpers";

const ContractsTab: React.FC<{ maintenance: MaintenanceModel }> = ({
  maintenance,
}) => {
  const canEditMaintenance = usePermissions().maintenances.edit;
  return (
    <Box>
      {canEditMaintenance && (
        <UploadFileDrawer
          maintenance={maintenance}
          title={"Subir Contrato"}
          color="success"
          slugType={"contrato"}
          ticket={undefined}
          areaUuid={undefined}
          checklistItemUuid={undefined}
          alignButton={undefined}
          variant={undefined}
          community={undefined}
          visitReport={undefined}
          fromReport={undefined}
          user={undefined}
        />
      )}
      <ContractsTable maintenance={maintenance} />
    </Box>
  );
};

export default ContractsTab;
