// @mui material components
import { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress, Icon } from "@mui/material";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiDatePicker from "components/SuiDatePicker";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import FileInput from "components/FileInput";
import MergePDFComponent from "components/MergePDF";
import { toastNotification } from "components/ui/ToastNotification";

// Redux actions and selectors
import { useDispatch, useSelector } from "react-redux";
import {
  getProvidersByManagement,
  createProviderByManagement,
} from "redux/actions/providers";
import {
  getDocumentsTags,
  addDocument,
  updateDocument,
} from "redux/actions/documents";
import { createCertification } from "redux/actions/maintenance";

// Other imports
import { useParams } from "react-router-dom";
import heic2any from "heic2any";
import { useAuthenticationStore } from "stores/authentication";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import CreateProvider from "layouts/pages/providers/Create";

// Constants
const DOCUMENT_TYPES = [
  { label: "Cotización", value: "cotizacion" },
  { label: "Certificación", value: "certificacion" },
  { label: "Cheque", value: "cheque" },
  { label: "Circular", value: "circular" },
  { label: "Comprobante de pago", value: "comprobante-de-pago" },
  { label: "Contrato", value: "contrato" },
  { label: "Contabilidad", value: "contabilidad" },
  { label: "Factura", value: "factura" },
  { label: "Imagen", value: "imagen" },
  { label: "Informe", value: "informe" },
  { label: "Legal", value: "legal" },
  { label: "Seguros", value: "seguros" },
  { label: "RRHH", value: "rrhh" },
  { label: "Orden de Trabajo", value: "orden-de-trabajo" },
  { label: "Otro", value: "otro" },
];

const DocumentForm = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { userProfile } = useAuthenticationStore.getState();

  const isEditMode = !!props?.file;
  const initialFileData = isEditMode
    ? {
        fileName: props?.file?.name || "",
        fileType: props?.file?.type?.slug || "",
        tags:
          props?.file?.tags?.map((tag) => ({
            value: tag.name,
            label: tag.name,
          })) || [],
        proveedor: props?.file?.service_provider?.uuid || null,
        price: parseFloat(props?.file?.price || 0).toLocaleString("es-CL", {
          useGrouping: true,
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
        community: props?.community?.slug || slug || "",
        currencyType: props?.file?.currency || "CLP",
        selectedDate: props?.file?.expires_on || null,
        description: props?.file?.observations || "",
        taxes: props?.file?.tax || "",
        preview: props?.file?.status || null,
      }
    : {
        // Valores por defecto para nuevo documento
        fileName: "",
        fileType: props?.slugType || "",
        tags: [],
        proveedor: null,
        price: "0",
        community: props?.community?.slug || slug || "",
        currencyType: "CLP",
        selectedDate: null,
        description: "",
        taxes: "",
        preview: null,
      };

  console.log("initialFileData", initialFileData);

  const [fileData, setFileData] = useState(initialFileData);

  const [fileState, setFileState] = useState({
    isFilePicked: false,
    files: [],
    images: [],
    image: null,
    editDocument: false,
  });

  const [loading, setLoading] = useState(false);
  const [providers, setProviders] = useState([]);
  const [documentsTags, setDocumentsTags] = useState([]);

  const providersResponse = useSelector(
    (state) => state?.providers?.getProvidersByManagement || {}
  );
  const documentsTagsResponse = useSelector(
    (state) => state?.documents?.getDocumentsTags || {}
  );
  const createProviderResponse = useSelector(
    (state) => state?.providers?.createProviderByManagement || {}
  );
  const uploadDocumentResponse = useSelector(
    (state) => state?.documents?.addDocument || {}
  );
  const updateDocumentResponse = useSelector(
    (state) => state?.documents?.updateDocument || {}
  );
  
  const communitiesResponse = useGetCommunities();
  const communities = communitiesResponse.data?.map((community) => ({
    label: community.name,
    value: community.slug,
  }));

  const compressImage = useCallback(
    async (file) => {
      let processedFile = file;
      if (file.type === "image/heic") {
        try {
          processedFile = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.6,
          });
        } catch (error) {
          console.error("Error converting image:", error);
          return;
        }
      }

      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxDimension = 800;
        let { width, height } = img;

        if (width > height && width > maxDimension) {
          height *= maxDimension / width;
          width = maxDimension;
        } else if (height > maxDimension) {
          width *= maxDimension / height;
          height = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File([blob], file.name, {
              type: "image/jpeg",
            });
            if (isEditMode) {
              setFileState((prevState) => ({
                ...prevState,
                image: compressedFile,
              }));
            } else {
              setFileState((prevState) => ({
                ...prevState,
                images: [...prevState.images, compressedFile],
              }));
            }
          },
          "image/jpeg",
          0.7
        );
      };

      img.src = URL.createObjectURL(processedFile);
    },
    [isEditMode]
  );

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (isEditMode) {
      const selectedFile = selectedFiles[0];
      setFileState((prevState) => ({
        ...prevState,
        file: selectedFile,
        isFilePicked: true,
      }));
      if (fileData.fileType === "imagen") {
        compressImage(selectedFile);
      }
    } else {
      setFileState((prevState) => ({
        ...prevState,
        files: selectedFiles,
        images: [],
        isFilePicked: true,
      }));
      if (fileData.fileType === "imagen") {
        selectedFiles.forEach((file) => compressImage(file));
      }
    }
  };

  useEffect(() => {
    const slugManagement = userProfile.management.slug;
    dispatch(getProvidersByManagement(slugManagement));
    dispatch(getDocumentsTags());

    // Establecer la comunidad basada en los props
    if (slug) {
      setFileData((prevState) => ({ ...prevState, community: slug }));
    }
    if (props.community) {
      setFileData((prevState) => ({
        ...prevState,
        community: props.community,
      }));
    }
    if (props.ticket) {
      setFileData((prevState) => ({
        ...prevState,
        community: props.ticket.community.slug,
      }));
    }
    if (props.maintenance) {
      setFileData((prevState) => ({
        ...prevState,
        community: props.maintenance.community.slug,
      }));
    }
  }, [
    dispatch,
    userProfile.management.slug,
    slug,
    props.community,
    props.ticket,
    props.maintenance,
  ]);

  useEffect(() => {
    if (createProviderResponse?.data) {
      const slugManagement = userProfile.management.slug;
      dispatch(getProvidersByManagement(slugManagement));
    }
  }, [createProviderResponse, dispatch, userProfile.management.slug]);

  useEffect(() => {
    if (providersResponse?.data) {
      const options = providersResponse.data.map((provider) => ({
        label: provider.executive_name || provider.razon_social,
        value: provider.uuid,
      }));
      setProviders(options);
    }
  }, [providersResponse]);

  useEffect(() => {
    if (documentsTagsResponse?.data) {
      const options = documentsTagsResponse.data.map((tag) => ({
        label: tag.name,
        value: tag.uuid,
      }));
      setDocumentsTags(options);
    }
  }, [documentsTagsResponse]);

  useEffect(() => {
    if (uploadDocumentResponse?.data || updateDocumentResponse?.data) {
      setLoading(false);
      // Puedes cerrar el drawer después de la subida si lo deseas
      // props.toggleDrawer('right', false);
    }
  }, [uploadDocumentResponse, updateDocumentResponse]);

  const handleSubmit = () => {
    if(loading) return;
    if (!fileData.fileName) {
      toastNotification.error({
        title: "Documento no creado",
        message: "El documento debe tener un nombre",
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();

    formData.append("name", fileData.fileName);
    formData.append("observations", fileData.description);
    if (fileData.community)
      formData.append("community_slug", fileData.community);
    if (fileData.proveedor)
      formData.append("service_provider_uuid", fileData.proveedor);
    formData.append(
      "price",
      fileData.price.replace(/[^\d,]/g, "").replace(",", ".")
    );
    formData.append("type_slug", fileData.fileType);
    formData.append("status", fileData.preview);
    formData.append("tax", fileData.taxes);
    formData.append("currency", fileData.currencyType);
    if (fileData.selectedDate)
      formData.append("expires_on", fileData.selectedDate);

    const tagsArray = JSON.stringify(
      fileData.tags.map((tag) => ({ name: tag.label }))
    );
    formData.append("tags", tagsArray);

    // Incluir props adicionales
    if (props.checklistItemUuid)
      formData.append("checklist_item_uuid", props.checklistItemUuid);
    if (props.areaUuid) formData.append("area_uuid", props.areaUuid);
    if (props.ticket) formData.append("ticket_uuid", props.ticket.uuid);
    if (props.visit) formData.append("visit_uuid", props.visit.uuid);
    if (props.visitReport) formData.append("report_uuid", props.visitReport);
    if (props.maintenance) {
      formData.append("maintenance_uuid", props.maintenance.uuid);
      formData.append("community_slug", props.maintenance.community.slug);
    }
    if (props.user) {
      formData.append("user_email", props.user.email);
    }

    if (isEditMode) {
      if (fileState.isFilePicked) {
        if (fileData.fileType === "imagen" && fileState.image) {
          formData.append("file", fileState.image);
        } else if (fileState.file) {
          formData.append("file", fileState.file);
        }
      }
      dispatch(updateDocument(props.file.uuid, formData));
    } else {
      if (fileData.fileType === "imagen") {
        fileState.images.forEach((file) => {
          const individualFormData = new FormData();
          for (let [key, value] of formData.entries()) {
            individualFormData.append(key, value);
          }
          individualFormData.append("file", file);
          dispatch(addDocument(individualFormData));
        });
      } else {
        fileState.files.forEach((file) => {
          const individualFormData = new FormData();
          for (let [key, value] of formData.entries()) {
            individualFormData.append(key, value);
          }
          individualFormData.append("file", file);

          if (fileData.fileType === "certificacion" && props.maintenance) {
            individualFormData.append("due_date", fileData.selectedDate);
            const documentFile = JSON.stringify({
              name: fileData.fileName,
              tags: fileData.tags.map((tag) => ({ name: tag.label })),
            });
            individualFormData.append("document", documentFile);
            dispatch(createCertification(individualFormData));
          } else {
            dispatch(addDocument(individualFormData));
          }
        });
      }
    }
  };

  const renderFileInput = () => {
    return (
      <Grid container width="100%" direction="row">
        <Grid item xs={12} p={2} pt={0}>
          <SuiBox mb={0} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Documento
            </SuiTypography>{" "}
            {isEditMode && !fileState.editDocument ? (
              <>
                <SuiTypography
                  component="a"
                  href={props.file}
                  target="_blank"
                  variant="caption"
                  fontWeight="bold"
                  color="info"
                >
                  Ver
                </SuiTypography>
                <SuiTypography
                  component="p"
                  variant="caption"
                  fontWeight="bold"
                  color="info"
                  onClick={() =>
                    setFileState((prevState) => ({
                      ...prevState,
                      editDocument: true,
                    }))
                  }
                >
                  Editar
                </SuiTypography>
              </>
            ) : (
              <FileInput
                onChange={handleFileChange}
                files={
                  isEditMode
                    ? fileState.image
                      ? [fileState.image]
                      : fileState.file
                      ? [fileState.file]
                      : []
                    : fileState.files
                }
              />
            )}
          </SuiBox>
        </Grid>

        {fileState.editDocument && isEditMode && (
          <Grid item xs={12} p={2} pt={0}>
            <MergePDFComponent />
          </Grid>
        )}

        <Grid item xs={12} p={2} pt={0}>
          <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Nombre del documento
            </SuiTypography>
          </SuiBox>
          <SuiInput
            size="small"
            type="text"
            value={fileData.fileName}
            onChange={(e) =>
              setFileData((prevState) => ({
                ...prevState,
                fileName: e.target.value,
              }))
            }
            placeholder="Nombre del documento"
            // Cambiar el color del borde si está vacío
            error={fileData.fileName.length == 0}
          />
          <SuiTypography variant="caption" color="error">
            {fileData.fileName.length == 0 ? "Este campo es requerido" : ""}
          </SuiTypography>
        </Grid>
        {!slug && !props.community && !props.ticket && !props.maintenance && (
          <Grid item xs={12} p={2} pt={0}>
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Comunidad
              </SuiTypography>
            </SuiBox>
            <SuiSelect
              size="small"
              onChange={(e) =>
                setFileData((prevState) => ({
                  ...prevState,
                  community: e.value,
                }))
              }
              placeholder="Comunidad"
              value={fileData.community}
              options={communities}
            />
          </Grid>
        )}
        {fileData.fileType !== "imagen" && (
          <>
            <Grid item xs={12}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={11} p={2} pt={0}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Proveedor
                    </SuiTypography>
                  </SuiBox>
                  <SuiSelect
                    size="small"
                    onChange={(e) =>
                      setFileData((prevState) => ({
                        ...prevState,
                        proveedor: e.value,
                      }))
                    }
                    placeholder="Proveedor"
                    value={fileData.proveedor}
                    options={providers}
                  />
                </Grid>
                <Grid item xs={1} pt={2} pr={2}>
                  {/* Assuming CreateProvider is imported */}
                  <CreateProvider icon />
                </Grid>
              </Grid>
            </Grid>
            {[
              "certificacion",
              "cotizacion",
              "factura",
              "cheque",
              "comprobante-de-pago",
              "contrato",
              "seguros",
              "contabilidad",
              "rrhh",
              "otro",
            ].includes(fileData.fileType) && (
              <Grid item xs={12} p={2} pt={0}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Fecha expiración
                </SuiTypography>
                <SuiDatePicker
                  style={{ zIndex: 1000000 }}
                  value={fileData.selectedDate}
                  error={!fileData.selectedDate}
                  onChange={(e) =>
                    setFileData((prevState) => ({
                      ...prevState,
                      selectedDate: e[0].toISOString().split("T")[0],
                    }))
                  }
                />
                <SuiTypography variant="caption" color="error">
                  {!fileData.selectedDate ? "Este campo es requerido" : ""}
                </SuiTypography>
              </Grid>
            )}
            {[
              "cotizacion",
              "factura",
              "cheque",
              "comprobante-de-pago",
            ].includes(fileData.fileType) && (
              <>
                <Grid item xs={12} p={2} pt={0}>
                  <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Precio
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput
                    size="small"
                    value={fileData.price}
                    onChange={(e) => {
                      let inputValue = e.target.value.replace(/[^\d,]/g, "");
                      let lastIsComma = inputValue.endsWith(",");
                      inputValue = inputValue
                        .replace(".", "")
                        .replace(",", ".");
                      let formattedValue = parseFloat(
                        inputValue
                      ).toLocaleString("es-CL", {
                        useGrouping: true,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      });
                      if (lastIsComma) {
                        formattedValue += ",";
                      }
                      if (inputValue === "") {
                        formattedValue = "";
                      }
                      setFileData((prevState) => ({
                        ...prevState,
                        price: formattedValue,
                      }));
                    }}
                    placeholder="Precio"
                  />
                </Grid>
                <Grid item xs={12} p={2} pt={0}>
                  <SuiBox>
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Impuestos
                    </SuiTypography>
                    <SuiSelect
                      size="small"
                      value={fileData.taxes}
                      onChange={(e) =>
                        setFileData((prevState) => ({
                          ...prevState,
                          taxes: e.value,
                        }))
                      }
                      placeholder="...."
                      options={[
                        { label: "+ IVA", value: "+ IVA" },
                        {
                          label: "+ Impuesto Boleta",
                          value: "+ Impuesto Boleta",
                        },
                        { label: "N/A", value: "" },
                      ]}
                    />
                  </SuiBox>
                </Grid>
              </>
            )}
            {fileData.fileType === "cotizacion" && (
              <Grid item xs={12} p={2} pt={0}>
                <SuiBox>
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Valoración en
                  </SuiTypography>
                  <SuiSelect
                    size="small"
                    value={fileData.currencyType}
                    onChange={(e) =>
                      setFileData((prevState) => ({
                        ...prevState,
                        currencyType: e.value,
                      }))
                    }
                    placeholder="...."
                    options={[
                      { label: "CLP", value: "CLP" },
                      { label: "UF", value: "UF" },
                    ]}
                  />
                </SuiBox>
              </Grid>
            )}
          </>
        )}
        {fileData.fileType === "imagen" && (
          <Grid item xs={12}>
            <SuiBox p={2} pt={0}>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Antes o Después
              </SuiTypography>
              <SuiSelect
                size="small"
                value={fileData.preview}
                onChange={(e) =>
                  setFileData((prevState) => ({
                    ...prevState,
                    preview: e.value,
                  }))
                }
                placeholder="Antes o Después"
                options={[
                  { label: "Antes", value: "Antes" },
                  { label: "Después", value: "Después" },
                  { label: "Otro", value: "Otro" },
                ]}
              />
            </SuiBox>
          </Grid>
        )}

        <Grid item xs={12} p={2} pt={0}>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Observación
              </SuiTypography>
            </SuiBox>
            <SuiInput
              size="small"
              value={fileData.description}
              onChange={(e) =>
                setFileData((prevState) => ({
                  ...prevState,
                  description: e.target.value,
                }))
              }
              placeholder="Observación"
              multiline
              rows={5}
            />
          </SuiBox>
        </Grid>
        <Grid item xs={12} p={2} pt={0}>
          <SuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Etiquetas documento
              </SuiTypography>
            </SuiBox>
            <SuiSelect
              size="small"
              options={documentsTags}
              multiple
              onInputChange={(e) => {
                setDocumentsTags((prevTags) => [
                  ...prevTags,
                  { label: e, value: e },
                ]);
              }}
              onChange={(e) =>
                setFileData((prevState) => ({ ...prevState, tags: e }))
              }
              isMulti
              value={fileData.tags}
            />
          </SuiBox>
        </Grid>
        <div style={{ textAlign: "center", width: "100%", padding: 10 }}>
          <SuiButton
            size="small"
            variant="gradient"
            onClick={handleSubmit}
            color="success"
          >
            {loading ? <CircularProgress size={12} /> : "Guardar"}
          </SuiButton>
        </div>
      </Grid>
    );
  };

  return (
    <SuiBox height="100%">
      <Card
        style={{
          marginBottom: 20,
          overflow: "visible",
          width: "100%",
          height: "100%",
        }}
      >
        <SuiBox position="relative">
          <SuiBox position="absolute" top={0} right={0} p={2}>
            <Icon onClick={() => props.toggleDrawer("right", false)}>
              close
            </Icon>
          </SuiBox>
        </SuiBox>
        <Grid container alignItems="center">
          <SuiTypography component="h6" fontWeight="bold" p={3}>
            {isEditMode ? "Editar documento" : "Subir documento"}
          </SuiTypography>
          {!props.fromReport && (
            <Grid item xs={12}>
              <SuiBox px={4} pb={1}>
                <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Tipo de documento
                  </SuiTypography>
                </SuiBox>
                <SuiSelect
                  option={fileData.fileType}
                  size="small"
                  isDisabled={props.slugType ? true : false}
                  onChange={(e) =>
                    setFileData((prevState) => {
                      return { ...prevState, fileType: e.value };
                    })
                  }
                  placeholder="Tipo de archivo"
                  options={DOCUMENT_TYPES}
                  value={fileData.fileType}
                />
              </SuiBox>
            </Grid>
          )}
          {fileData.fileType && (
            <SuiBox p={2} pt={0}>
              {renderFileInput()}
            </SuiBox>
          )}
        </Grid>
      </Card>
    </SuiBox>
  );
};

export default DocumentForm;
