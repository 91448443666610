import React, { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { ChecklistModel } from 'api/services/checklist/models';
import useAddChecklistItem from 'api/services/checklist/mutations/AddChecklistItem';
import InputControlled from 'components/forms/controlled/InputControlled';
import { SuiButtonTs } from 'components/SuiButton';
import { UUID } from 'crypto';
import { useForm } from 'react-hook-form';

interface Props {
  objectUUID: UUID;
  checklist: ChecklistModel;
  onEdit?: (checklist: ChecklistModel) => void;
}
interface FormValues {
  name: string;
}
const NewChecklistItem: FC<Props> = ({ objectUUID, checklist, onEdit }) => {
  const { mutate: addChecklistItem, isPending: isSubmiting } = useAddChecklistItem({
    checklistUUID: checklist.uuid,
    objectUUID,
  });
  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    addChecklistItem(data, {
      onSuccess: (newItem) => {
        onEdit?.({
          ...checklist,
          items: [...checklist.items, newItem],
        });
        reset();
      },
    });
  });

  return (
    <Box display={'flex'} gap={2}>
      <Box sx={{ flex: 1 }}>
        <InputControlled control={control} name="name" placeholder="Nueva actividad" />
      </Box>
      <Box>
        <SuiButtonTs
          variant="gradient"
          color="success"
          size="small"
          disabled={!formState.isDirty}
          onClick={onSubmit}
        >
          <Box width={80}>
            {isSubmiting ? <CircularProgress size={16} color="inherit" /> : 'Agregar'}
          </Box>
        </SuiButtonTs>
      </Box>
    </Box>
  );
};

export default NewChecklistItem;
