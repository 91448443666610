import { Box, Checkbox, Divider, Grid, Typography } from "@mui/material";
import { TicketPriority, TicketStatus } from "api/services/tickets/models";
import { SuiBadgeTs } from "components/SuiBadge";
import { SuiBoxTs } from "components/SuiBox";
import { SuiButtonTs } from "components/SuiButton";
import SuiInputTs from "components/SuiInput/SuiInputTs";
import { SuiSelectTs } from "components/SuiSelect";
import { FC, useEffect, useState } from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { FiltersProps, PriorityFilter } from "./types";
import { PRIORITY_ARR, STATUS_ARR, ORDER_BY_OPTIONS } from "./constants";
import useDebounce from "utils/Hooks/useDebounce";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { useGetManagementStaff } from "api/services/staff/queries/getManagementStaff";
import { useExportCenterFiltersStore } from "../store";

const Filters: FC<FiltersProps> = ({ setFilters }) => {
  const { dataAsOptions: communitiesOptions } = useGetCommunities();
  const { dataAsOptions: membersOptions } = useGetManagementStaff();

  const { community, setCommunity } = useExportCenterFiltersStore();
  const [title, setTitle] = useState<string>("");
  const [priorityFilter, setPriorityFilter] = useState<PriorityFilter>({});
  const [statusFilter, setStatsuFilter] = useState({});
  const [orderByFilter, setOrderByFilter] = useState<string>(
    ORDER_BY_OPTIONS[0].value
  );
  const [sortBy, setSortBy] = useState<"" | "-">("");
  const [activeTicketsFilter, setActiveTicketsFilter] =
    useState<boolean>(false);
  const [memberFilter, setMemberFilter] = useState("");
  const [responsibleFilter, setResponsibleFilter] = useState("");
  const debounceFilters = useDebounce(() => {
    setFilters({
      title: title,
      community,
      member: memberFilter,
      priorityFilter,
      statusFilter,
      orderByFilter,
      sortBy,
      activeTicketsFilter,
      responsible: responsibleFilter,
    });
  }, 1000);
  useEffect(() => {
    debounceFilters();
  }, [
    title,
    community,
    memberFilter,
    priorityFilter,
    statusFilter,
    orderByFilter,
    sortBy,
    activeTicketsFilter,
    responsibleFilter,
  ]);

  const handlePriorityFilter = (value: TicketPriority) =>
    setPriorityFilter((prev) => {
      if (prev[value] === undefined) return { ...prev, [value]: true };
      else return { ...prev, [value]: undefined };
    });
  const handleStatusFilter = (value: TicketStatus) =>
    setStatsuFilter((prev) => {
      if (prev[value] === undefined) return { ...prev, [value]: true };
      else return { ...prev, [value]: undefined };
    });

  return (
    <Box>
      <Typography variant="h5" fontWeight="medium" style={{ marginBottom: 3 }}>
        Filtros
      </Typography>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" fontWeight="medium">
            Título
          </Typography>
          <SuiInputTs
            size="small"
            placeholder="Título"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ overflow: "visible" }}>
          <Typography variant="h6" fontWeight="medium">
            Comunidad
          </Typography>
          <SuiSelectTs
            size="small"
            value={community}
            placeholder="Comunidad"
            style={{ zIndex: 1000, width: "100%" }}
            onChange={(e) => setCommunity(e.value)}
            options={[{ label: "Todas", value: null }, ...communitiesOptions]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" fontWeight="medium">
            Responsable
          </Typography>
          <SuiSelectTs
            size="small"
            value={responsibleFilter}
            placeholder="Responsable"
            style={{ zIndex: 1000, width: "100%" }}
            options={[{ value: "", label: "Todos" }, ...membersOptions]}
            onChange={(e) => setResponsibleFilter(e.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" fontWeight="medium">
            Colaborador
          </Typography>
          <SuiSelectTs
            size="small"
            value={memberFilter}
            placeholder="Colaborador"
            style={{ zIndex: 1000, width: "100%" }}
            options={[{ value: "", label: "Todos" }, ...membersOptions]}
            onChange={(e) => setMemberFilter(e.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SuiBoxTs>
            <Typography variant="h6" fontWeight="medium">
              Ordenar
            </Typography>
            <SuiBoxTs
              display="flex"
              style={{ width: "100%" }}
              alignItems="center"
              mr={2}
            >
              <SuiSelectTs
                size="small"
                onChange={(e) => setOrderByFilter(e.value)}
                options={ORDER_BY_OPTIONS}
                value={orderByFilter}
              />
              <SuiButtonTs
                variant="icon"
                circular={true}
                onClick={() => setSortBy((prev) => (prev === "-" ? "" : "-"))}
              >
                {sortBy === "-" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
              </SuiButtonTs>
            </SuiBoxTs>
          </SuiBoxTs>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6" fontWeight="medium">
            Prioridad
          </Typography>
          {PRIORITY_ARR.map((item) => {
            return (
              <SuiBadgeTs
                key={item}
                color={priorityFilter[item] ? "success" : "light"}
                style={{ cursor: "pointer" }}
                badgeContent={item}
                variant="gradient"
                onClick={() => handlePriorityFilter(item)}
              />
            );
          })}
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" fontWeight="medium">
            Estado
          </Typography>
          <SuiBoxTs display="flex" flexWrap="wrap">
            {STATUS_ARR.map((status) => {
              return (
                <SuiBoxTs
                  key={status}
                  style={{ cursor: "pointer" }}
                  mb={1}
                  onClick={() => handleStatusFilter(status)}
                >
                  <SuiBadgeTs
                    color={statusFilter[status] ? "success" : "light"}
                    variant="gradient"
                    badgeContent={status}
                  />
                </SuiBoxTs>
              );
            })}
          </SuiBoxTs>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="h6" fontWeight="medium">
            Tareas Inactivas
          </Typography>
          <Checkbox
            checked={activeTicketsFilter}
            onChange={() => setActiveTicketsFilter(!activeTicketsFilter)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
