import React, { FC } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { TicketModel } from 'api/services/tickets/models';
import CommunityCard from './CommunityCard';
import TicketTitleCard from './TitleCard';
import Timeline from '../Timeline';
import DescriptionCard from './DescriptionCard';
import TicketDocumentsImagesCard from './ImagesSlider';
import ActivitiesChecklist from './ActivitiesChecklist';
import TicketDocumentsTable from './DocumentsTable';
import DocumentsPricing from './DocumentsPricing';
import ActivePolls from './ActivePolls';
import logo from 'assets/images/logo-blue.png';

interface Props {
  ticket: TicketModel;
  editEnabled?: boolean;
}

const TicketDetailPublic: FC<Props> = ({ ticket }) => {
  const hasImages = ticket.documents.some(({ type }) => type.slug === 'imagen');
  const hasActivePolls = ticket.polls.length > 0;
  const hasChecklists = ticket.checklists.length > 0;
  const hasDocumentPricing = ticket.documents.some(({ type }) => type.slug === 'cotizacion');

  return (
    <section>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box>
          <a target="_blank" href="https://app.swappi.cl">
            <img src={logo} style={{ width: '10rem', margin: '0 auto' }} />
          </a>
        </Box>
        <Box>
          <a target="_blank" href={ticket.management?.web_site}>
            <img src={ticket.management?.logo} style={{ width: '10rem', margin: '0 auto' }} />
          </a>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Stack spacing={2}>
            <CommunityCard ticket={ticket} />
            <Timeline ticket={ticket} />
          </Stack>
        </Grid>
        <Grid item md={9}>
          <Stack spacing={2}>
            <TicketTitleCard ticket={ticket} />
            <DescriptionCard ticket={ticket} />
            {hasImages && <TicketDocumentsImagesCard ticket={ticket} />}
            {hasChecklists && <ActivitiesChecklist ticket={ticket} />}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            {hasDocumentPricing && <DocumentsPricing ticket={ticket} />}
            {hasActivePolls && <ActivePolls ticket={ticket} />}
            <TicketDocumentsTable ticket={ticket} />
          </Stack>
        </Grid>
      </Grid>
    </section>
  );
};

export default TicketDetailPublic;
