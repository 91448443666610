import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { PLACEHOLDER_PAGE, PageResponse } from "api/utils/queries";
import { API_URL } from "redux/actions/types";
import { TicketItemModel } from "../models";
import QUERY_KEYS from "api/utils/keys";
import { UUID } from "crypto";
import { Email } from "types/models";

export interface GetTicketsListParams {
  page?: number;
  page_size?: number;
  priority__in?: string;
  status__in?: string;
  communities?: string;
  o?: string;
  search?: string;
  is_inactive?: boolean;
  assignees?: string;
  is_finished?: boolean;
  is_after_sale?: boolean;
  area_uuid?: UUID;
  maintenance_uuid?: UUID;
  responsible?: Email;
  service_provider?: UUID;
}
export type TicketsTablePage = PageResponse<TicketItemModel>;

export const useGetTicketsPage = (params: GetTicketsListParams) => {
  const query = useQuery<TicketsTablePage>({
    queryKey: [QUERY_KEYS.TICKETS_TABLE, params],
    queryFn: async () => {
      const url = `${API_URL}/tickets/list/`;
      return await axios
        .get<TicketsTablePage>(url, { params })
        .then((res) => res.data);
    },
  });
  const data: TicketsTablePage = query.data ?? PLACEHOLDER_PAGE;
  return { ...query, data };
};
